// Entertainment
import entertainment1 from './images/entertainment_1.jpg';
import entertainment2 from './images/entertainment_2.jpg';
import entertainment3 from './images/entertainment_3.jpg';

// My News
import mynews1 from './images/mynews_1.jpg';
import mynews2 from './images/mynews_2.jpg';
import mynews3 from './images/mynews_3.jpg';
import mynews4 from './images/mynews_4.jpg';
import mynews5 from './images/mynews_5.jpg';

// Opinion
import opinions1 from './images/opinions_1.jpg';
import opinions2 from './images/opinions_2.jpg';
import opinions3 from './images/opinions_3.jpg';

// Politics
import politics1 from './images/politics_1.jpg';
import politics2 from './images/politics_2.jpg';
import politics3 from './images/politics_3.jpg';

// Sports
import sports1 from './images/sports_1.jpg';
import sports2 from './images/sports_2.jpg';
import sports3 from './images/sports_3.jpg';
import sports4 from './images/sports_4.jpg';
import sports5 from './images/sports_5.jpg';

// Technology
import technology1 from './images/technology_1.jpg';
import technology2 from './images/technology_2.jpg';
import technology3 from './images/technology_3.jpg';

// Contest
import contest1 from './images/contest_1.jpg';

const bgImageManager = (topic) => {
  const images = {
    default: [
      mynews1,
      mynews2,
      mynews3,
      mynews4,
      mynews5,
      opinions1,
      opinions2,
      opinions3,
    ],
    entertainment: [
      entertainment1,
      entertainment2,
      entertainment3,
    ],
    politics: [
      politics1,
      politics2,
      politics3,
    ],
    sports: [
      sports1,
      sports2,
      sports3,
      sports4,
      sports5,
    ],
    technology: [
      technology1,
      technology2,
      technology3,
    ],
    contest: [
      contest1,
    ],
  };

  const imageArray = images[topic] ? images[topic] : images.default;

  const index = Math.floor((Math.random() * imageArray.length));

  return imageArray[index];
};

export default bgImageManager;
